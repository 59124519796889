<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title=" التفاصيل">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-row>
      
            <b-col md="3" xl="3">
              
                <b-form-group
                  label-for="title"
                
                >
             
                <label> الكود</label>
                <br/>
                {{ Form.code }}
              </b-form-group>
           
            </b-col>
            <b-col md="3" xl="3">
              
              <b-form-group
                label-for="title"
              
              >
           
              <label> التاريخ</label>
              <br/>
              {{ Form.date }}
            </b-form-group>
         
          </b-col>
          <b-col md="3" xl="3" v-if="Form.from_party_id">
              
              <b-form-group
                label-for="title"
              
              >
           
              <label> المصدر</label>
              <br/>
              {{ Form.from_party_id.name }}
            </b-form-group>
         
          </b-col>
          <b-col md="3" xl="3" v-if="Form.to_party_id">
              
              <b-form-group
                label-for="title"
              
              >
           
              <label> الوجهة</label>
              <br/>
              {{ Form.to_party_id.name }}
            </b-form-group>
         
          </b-col>
          <b-col md="3" xl="3">
              
              <b-form-group
                label-for="title"
              
              >
           
              <label> صاحب الطلب</label>
              <br/>
              {{ Form.request_by_user }}
            </b-form-group>
         
          </b-col>
          <b-col md="3" xl="3">
              
              <b-form-group
                label-for="title"
              
              >
           
              <label> القسم</label>
              <br/>
              {{ Form.department_id}}
            </b-form-group>
         
          </b-col>
             <b-col md="8" xl="8">
            <b-form-group label-for="docNote">
              <label>الملاحظات</label>
              <b-form-textarea
                id="textarea-default"
                v-model="notes"
                placeholder="الملاحظات"
                rows="1"
              />
            </b-form-group>
             </b-col>
          
          </b-row>
           <b-table
        ref="refServiceListTable"
        class="position-relative"
        :items="Form.details"
        responsive
        :fields="tableColumns"
        
        show-empty
        empty-text="No matching records found"

      >
    
 </b-table>
      
          
        </validation-observer>
        </b-card-code>
      </b-form>
      <b-row>
            <b-col md="4" xl="3">
              <b-button variant="success" @click="UpdateCurrency(1)">
             
                <span class="align-middle"> موافق  </span>
              </b-button>
            </b-col>
              <b-col md="4" xl="3" class="mx-5">
              <b-button variant="danger" @click="UpdateCurrency(0)">
             
رفض              </b-button>
            </b-col>
          </b-row>
    </validation-observer>
    <!-- <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col> -->
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { required, max, is_earlier } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
    BTable,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
export default {


  components: {
    ToastificationContent,
    required,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
     BTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      Form:{},
      name: "",
      code:'',
      brand_name:'',
      optioneItemCategory:[],
      en_name: "",
      item_category_id:'',
      tableColumns: [
       
         { key: "item.name", label: "المادة"},
          { key: "item.code", label: "الكود"},
    { key: "unit.name", label: "الواحدة"},
    { key: "quantity", label: "الكمية"},

      ],
      notes:'',
      searchTerm: "",
      id: "",
   
    };
  },

  created() {
    this.getCurrency()

  },

  methods: {
  
 
    UpdateCurrency(status) {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then((success) => {
            if (success) {
              let data ={
                notes:this.notes,
                approve:status
              }
            
             
          
      this.$http.post(`/api/v1/complete-out-transactions-need-complete/${this.$route.params.id}`,data).then((res) => {
        //console.log(res);
        
        this.$swal({
          title: "",
          text: "تم   بنجاح",
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
       this.$router.push('/apps/complete-out-transaction')
      });
    }})})
    },
    

    getCurrency() {
      this.$http.get(`/api/v1/show-transaction/${this.$route.params.id}`).then((res) => {
        console.log('rewards', res)
       this.Form=res.data.data
       console.log(this.Form)
   
      });
    },

    //
  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>